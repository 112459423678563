<template>
  <vs-card>
    <form autocomplete="off" class="p-2">
      <h4>Setup a Discount</h4>
      <div class="flex flex-col space-y-2 mt-4">
          <div>
              <label class="block text-sm mb-1">Name this Discount</label>
              <vs-input
                :success="!errors.first('basic.name') && detail.name!=''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="(errors.first('basic.name') ? true : false)"
                v-validate="'required'"
                name="name"
                data-vv-as="Name"
                class="w-full lg:w-4/12"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.name')"
                v-model="detail.name"
              />
          </div>
          <div>
              <label class="block text-sm mb-1">Checkout Label</label>
              <vs-input
                :success="!errors.first('basic.checkoutLabel') && detail.checkoutLabel!=''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="(errors.first('basic.checkoutLabel') ? true : false)"
                v-validate="'required'"
                name="checkoutLabel"
                data-vv-as="Checkout Label"
                class="w-full lg:w-4/12"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.checkoutLabel')"
                v-model="detail.checkoutLabel"
              />
          </div>
      </div>


      <div class="w-full mt-12">
        <h6>Discount Type</h6>
        <!-- <p class="text-base mb-3 mt-2">Automatic Discount</p> -->
        
        <p class="flex items-center space-x-2 text-base mt-4">
          <vs-switch v-model="detail.discountType" vs-value="SpecificProduct" disabled />
          <span>Specific Products</span>
        </p>

        <div class="mt-4">
          <div class="w-full lg:w-4/12 mb-10">
            <AjaxSelect @onProductAdded="onProductAdded"/>
          </div>
          <template v-if="validProductDiscounts.length > 0">
            <div class="flex items-cennter space-x-4 w-full mb-4">
              <span class="w-16 text-sm">Enable</span>
              <span class="flex-1 text-sm">Product</span>
              <span class="w-2/12 text-sm">Price</span>
              <span class="w-2/12 text-sm">Adjusted Price</span>
              <span class="text-sm">Action</span>
            </div>
            <div class="flex items-cennter space-x-4 w-full" v-for="(productDiscount,index) in validProductDiscounts"  :key="index">
              <vs-checkbox v-model="productDiscount.isActive" class="w-12 h-12"></vs-checkbox>
              <div class="flex-1">
                <vs-input
                  :success="!errors.first('productName' + index) && productDiscount.name != ''"
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="errors.has('productName' + index)"
                  v-validate="'required|decimal:2|min_value:1'"
                  :name="'productName' + index"
                  :data-vv-as="'Adjusted Price'"
                  class="w-full"
                  :data-vv-scope="'basic' + index"
                  :danger-text="errors.first('productName' + index)"
                  v-model="productDiscount.name"
                />

              </div>
              <div class="w-2/12">
                <vs-input
                  :success="!errors.first('basic.price') && productDiscount.price !=''"
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="(errors.first('basic.price') ? true : false)"
                  v-validate="'required|decimal:2|min_value:1'"
                  name="price"
                  data-vv-as="Price"
                  disabled
                  class="w-full"
                  data-vv-scope="basic"
                  :danger-text="errors.first('basic.price')"
                  v-model="productDiscount.price"
                />
              </div>
              <div class="w-2/12">
                <vs-input
                  :success="!errors.first('adjustedPrice' + index) && productDiscount.adjustedPrice != 0"
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="errors.has('adjustedPrice' + index)"
                  v-validate="'required|decimal:2|min_value:1'"
                  :name="'adjustedPrice' + index"
                  :data-vv-as="'Adjusted Price'"
                  class="w-full"
                  :data-vv-scope="'scope' + index"
                  :danger-text="errors.first('adjustedPrice' + index)"
                  v-model="productDiscount.adjustedPrice"
                />
              </div>
              <div>
                <!-- <span class="removeProduct cursor-pointer rounded-lg text-white text-sm mt-12 px-3 py-1 " @click="removeProduct(productDiscount._id)"> Delete </span> -->
                <button type="button" class="flex items-center justify-center cursor-pointer rounded-lg text-red-600 text-base font-medium px-2 py-3 mt-1 w-10 h-10 border-none bg-red-100 hover:bg-red-200 active:bg-red-300 transition" @click="removeTempProduct(productDiscount)"> &#x2715; </button>
              </div>
            </div>
          </template>
        </div>
      </div>

      <div class="flex items-center justify-end space-x-2 mt-10">
        <vs-button
          :disabled="!validateForm"
          @click="editData"
          icon-pack="feather"
        >Update</vs-button>
        <vs-button color="danger" @click="cancelHandler" icon-pack="feather">Cancel</vs-button>
      </div>

    </form>
  </vs-card>

</template>

<script>
  import vSelect from 'vue-select';
  import { mapActions } from 'vuex';
  import AjaxSelect from "./AjaxSelect";

  export default {
    name: "EditDiscountModule",
    components: {
      vSelect,
      AjaxSelect
    },
    computed: {
      validateForm() {
        return !this.errors.any()
      },
      validProductDiscounts() {
        return this.detail.productDiscounts.filter(product => !isNaN(product.price));
      }
    },
    props:{
      discountId:{
        type: String,
      },
      detail: {
        type: Object,
        default: () => {
        },
      },
    },
    data() {
      return {
        params: {
          search: "",
          sort: "_id",
          dir: "desc",
          page: 1,
          limit: 25
        },
      };
    },
    methods:{
      async editData() {
        let allValid = true;

        const basic = await this.$validator.validateAll('basic');
        if (!basic) {
          this.$vs.notify({
            title: 'Error',
            text: 'Validation failed',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          });
          allValid = false; 
        }

        for (let i = 0; i < this.detail.productDiscounts.length; i++) {
          const isValid = await this.$validator.validateAll('scope' + i);
          if (!isValid) {
            allValid = false;
            this.$vs.notify({
              title: 'Error',
              text: `Validation failed for field ${i + 1}`,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            });
          }
        }

        if (!allValid) {
          return false;
        }

        this.$emit('editDetail', this.detail);
      },
      onProductAdded(product){
        this.$emit('newProductAdd',product)
      },
      cancelHandler() {
        this.$emit('cancelEdit');
      },
      removeTempProduct(product) {
        this.$emit('deleteTempItem', product)
      },
      removeProduct(product) {
        this.$emit('deleteItem', this.detail, product)
      },
    },
}
</script>

<style lang="scss">
  input {
    font-family: inherit !important;
  }
  .vs {
    &__selected {
      font-family: inherit !important;
      font-size: 16px !important;
    }
    &__dropdown {
      &-option {
        font-size: 16px !important;
      }
      &-toggle {
        border: 1px solid #84cdb8;
        padding: 0.4rem 0.6rem;
        border-radius: 0.5rem; 
        font-size: 16px;   
      }
    }
    &-input--input.normal {
      font-size: 16px !important;
    }
  }
</style>
